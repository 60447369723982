<template>
  <b-form-group class="custom-input__group">
    <template #label>
      <div class="custom-input__group-label">
        <p class="label-title">{{ label }}</p>

        <p class="maximum-words" v-if="maxChars">{{wordCount}}/{{ maxChars }}</p>
      </div>
    </template>
    <b-form-input v-if="type === 'number'" :placeholder="placeholder || 'Click here to start typing'" type="number" min="0" max="999" v-model="val" />
    <b-form-input v-else-if="type === 'text'" :placeholder="placeholder || 'Click here to start typing'" type="text" :maxLength="maxChars" v-model="val" />
    <b-form-textarea v-else-if="type === 'textarea'" rows="4" :placeholder="placeholder || 'Click here to start typing'" v-model="val" :maxLength="maxChars"/>
    <b-form-datepicker v-else-if="type === 'date'" v-model="val" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
    <b-form-select v-else-if="type === 'select'" :options="options" v-model="val" />
    <RichTextEditor v-else class="custom-input" v-model="val" :multipleRows="multipleRows" />
  </b-form-group>
</template>

<script>
import RichTextEditor from "../../components/common/RichTextEditor";

export default {
  name: 'CustomInput',
  props: ['incomingValue', 'wordCount', 'label', 'maxChars', 'type', 'placeholder', 'multipleRows', 'options'],
  components: { RichTextEditor },
  computed: {
    val: {
      get: function() {
          return this.incomingValue
      },
      set: function(value) {
          this.$emit('updateVal', value)
      }
    }
  }
}
</script>

<style lang="scss">
.custom-input__group-label {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
}

.custom-input__group {
  legend {
    padding-bottom: 2px !important;
  }
}

.label-title {
  color: $blue-900 !important;
  font-weight: 600 !important;

  margin-bottom: 0 !important;
  font-size: 14px !important;
}

.maximum-words {
  color: $blue-300;
  font-weight: 600;

  margin-bottom: 0;
  font-size: 12px;
}
</style>