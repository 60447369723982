<template>
    <div class="statistics">
        <b-row>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-4"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Hours Logged" class="time-logged__tour" icon="clock" :value="displayValue(statistics.hoursLogged)" :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-4"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Total Entries" class="total-entries__tour" icon="entries" :value="displayValue(statistics.totalEntries)"
                        :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-4"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Diary Entries" class="diary-entries__tour" icon="diary-entries" :value="displayValue(statistics.diaryEntries)"
                        :loading="loading" />
                </a></b-col>
            <b-col cols="6" sm="6" xl="3" class="no-select mb-4"><a class="stat-link" @click.prevent="goToEntries" href="#">
                    <TopStatistic header="Reflections" class="reflections-tour" icon="reflections" :value="displayValue(statistics.reflectiveEntries)"
                        :loading="loading" />
                </a></b-col>
            <b-col cols="12" class="no-select mb-4" v-if="settings.dashboardWidgets.showCPDHourCountdown && displayHoursLeft()">
                <b-card class="statistic-card">
                    <clock-outline-icon class="mr-4"/>
                    {{ displayHoursLeft() }}
                </b-card>
            </b-col>    
        </b-row>
    </div>
</template>

<script>
import TopStatistic from './TopStatistic'
export default {
    name: "TopStatistics",
    props: ['period', 'settings'],
    components: {
        TopStatistic
    },
    methods: {
        updateStatistics: function () {
            this.loading = true;
            this.$http.get(`statistics/${this.period}`).then(response => {
                this.statistics = response.data;
                this.failed = false;
            }).catch((resp) => {
                this.$httpError('Failed to load your statistics', resp);
                this.failed = true;
            }).then(() => {
                this.loading = false;
            });
        },
        displayValue: function (val) {
            if (this.failed) {
                return '--';
            }
            return val;
        },
        displayHoursLeft: function () {
            let hoursLeft = this.statistics.hoursLeft;
            let endDate = this.statistics.endDate;

            let daysLeft = Math.ceil((new Date(endDate) - new Date()) / (1000 * 60 * 60 * 24));

            if (hoursLeft < 0 || hoursLeft == null) {
                return '';
            }

            if (hoursLeft == 0) {
                return 'Congrats, you have met your CPD hours requirement.'
            }

            hoursLeft = Math.round(hoursLeft * 10) / 10;
            return `${daysLeft} days left to track ${hoursLeft} more hours of CPD to meet your requirements!`
        },
        goToEntries() {
            this.$router.push({ name: 'entries' });
        }
    },
    data: function () {
        return {
            statistics: {
                hoursLogged: 0,
                totalEntries: 0,
                diaryEntries: 0,
                reflectiveEntries: 0
            },
            loading: true,
            failed: false
        }
    },
    watch: {
        period: function (newValue) {
            this.period = newValue;
            this.updateStatistics();
        }
    },
    created: function () {
        this.updateStatistics();
    }
}
</script>

<style scoped>

.statistic-card {
    border: 1px solid #8ba7b8;
    border-radius: 4px;
}

a.stat-link,
a.stat-link:hover {
    text-decoration: none;
}


</style>