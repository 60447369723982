<template>
	<div class="profile">
		<ShowTourButton @handleClick="$refs.pageTourRef.handleOpen()" />
		<h4 class="dashboard-pages__title">Profile</h4>
        
        <b-card body-class="p-0">
            <b-row class="p-3">
                <b-col sm="12">
                    <ProfileBasicDetails />
                </b-col>
            </b-row>

            <b-tabs v-if="personalProfile" card active-nav-item-class="profile-tab__active"
                nav-wrapper-class="profile-tab__navbar professional-information__tour">
                <b-tab title="Professional Information & Qualifications">
                    <b-row>
                        <b-col sm="12" xl="6">
                            <b-row>
                                <b-col md="12">
                                    <CustomInput type="text" @updateVal="(val) => personalProfile.profession = val"
                                        label="Profession" :incomingValue="personalProfile.profession"
                                        :wordCount="$charCount(personalProfile.profession)" maxChars="200" />
                                </b-col>
                                <b-col md="12" v-show="branding == null">
                                    <CustomInput type="text" @updateVal="(val) => personalProfile.employer = val"
                                        label="Employer" :incomingValue="personalProfile.employer"
                                        :wordCount="$charCount(personalProfile.employer)" maxChars="200" />
                                </b-col>
                                <b-col md="12">
                                    <CustomInput type="text"
                                        @updateVal="(val) => personalProfile.businessWebsiteAssociated = val"
                                        label="Business or Website Associated"
                                        :incomingValue="personalProfile.businessWebsiteAssociated"
                                        :wordCount="$charCount(personalProfile.businessWebsiteAssociated)" maxChars="200" />
                                </b-col>
                                <b-col md="12" v-show="branding == null">
                                    <CustomInput type="text" @updateVal="(val) => personalProfile.registrationNumber = val"
                                        label="Professional Registration Reference/Number"
                                        :incomingValue="personalProfile.registrationNumber"
                                        :wordCount="$charCount(personalProfile.registrationNumber)" maxChars="200" />
                                </b-col>
                                <b-col md="12">
                                    <b-row>
                                        <b-col md="12">
                                            <CustomInput type="number" @updateVal="(val) => personalProfile.hoursPerYear = val"
                                            label="Required CPD Hours per Reporting Period"
                                             :incomingValue="personalProfile.hoursPerYear"
                                            :wordCount="$charCount(personalProfile.registrationNumber)" />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="6">
                                            <CustomInput type="date" label="Start Date" :incomingValue="personalProfile.startDate"
                                                @updateVal="(val) => personalProfile.startDate = val" />
                                        </b-col>
                                        <b-col md="6">
                                            <CustomInput type="select" label="Report Interval"
                                                :incomingValue="personalProfile.reportInterval"
                                                @updateVal="(val) => personalProfile.reportInterval = val" 
                                                :options="reportIntervalOptions" />
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="12">
                                    <CustomInput @updateVal="(val) => personalProfile.qualifications = val"
                                        label="Qualifications" :incomingValue="personalProfile.qualifications"
                                        :wordCount="$wordCount(personalProfile.qualifications)" maxChars="500"
                                        :multipleRows="true" />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="12" xl="6">
                            <b-card body-class="governing-bodies__card governing-body__tour">
                                <ProfileGoverningBody ref="governingBodyComponent"
                                    @updated-governing-bodies="governingBodiesUpdated" />
                                <hr>
                                <b-form-group label="Interests" v-show="branding == null">
                                    <b-form-checkbox-group class="ovr interests-checkboxes"
                                        v-model="personalProfile.interests" :options="interestOptions" switches />
                                </b-form-group>
                                <hr>
                                <h6 class="dashboard-pages__title">Entry tags</h6>
                                <ProfileEntryTags class="mt-2 profile-tag__entry-tour" />
                            </b-card>
                        </b-col>
                    </b-row>



                </b-tab>
                <b-tab title="Additional Information" v-if="getFeatures.includes('ProfileTabAdditionalInformation')">
                    <CustomInput @updateVal="(val) => personalProfile.summaryOfWork = val" label="Summary of Recent Work"
                        :incomingValue="personalProfile.summaryOfWork"
                        :wordCount="$wordCount(personalProfile.summaryOfWork)" maxChars="500" />
                    <CustomInput @updateVal="(val) => personalProfile.jobDescription = val" label="Job Description"
                        :incomingValue="personalProfile.jobDescription"
                        :wordCount="$wordCount(personalProfile.jobDescription)" maxChars="500" />
                    <CustomInput @updateVal="(val) => personalProfile.personalStatement = val" label="Personal Statement"
                        :incomingValue="personalProfile.personalStatement"
                        :wordCount="$wordCount(personalProfile.personalStatement)" maxChars="1500" />
                </b-tab>
                <b-tab title="HCPC Information" v-if="governingBodies.includes('HCPC')">
                    <CustomInput @updateVal="(val) => personalProfile.hcpcStandard1 = val" label="Meeting HCPC Standard 1"
                        :incomingValue="personalProfile.hcpcStandard1"
                        :wordCount="$wordCount(personalProfile.hcpcStandard1)" maxChars="500" />
                    <CustomInput @updateVal="(val) => personalProfile.hcpcStandard2 = val" label="Meeting HCPC Standard 2"
                        :incomingValue="personalProfile.hcpcStandard2"
                        :wordCount="$wordCount(personalProfile.hcpcStandard2)" maxChars="500" />
                    <CustomInput @updateVal="(val) => personalProfile.hcpcStandard3 = val" label="Meeting HCPC Standard 3"
                        :incomingValue="personalProfile.hcpcStandard3"
                        :wordCount="$wordCount(personalProfile.hcpcStandard3)" maxChars="500" />
                    <CustomInput @updateVal="(val) => personalProfile.hcpcStandard4 = val" label="Meeting HCPC Standard 4"
                        :incomingValue="personalProfile.hcpcStandard4"
                        :wordCount="$wordCount(personalProfile.hcpcStandard4)" maxChars="500" />
                </b-tab>

                <b-tab v-for="group in additionalFieldGroups" v-bind:key="group.title" :title="group.title">
                    <b-form-group v-for="fieldDefinition in group.fields" v-bind:key="fieldDefinition.fieldId"
                        :label="fieldDefinition.title">
                        <b-textarea rows="5" v-model="additionalFieldValues[fieldDefinition.fieldId]"
                            v-if="fieldDefinition.type === 'LongText'">

                        </b-textarea>
                        <b-input type="text" v-model="additionalFieldValues[fieldDefinition.fieldId]"
                            v-if="fieldDefinition.type === 'ShortText'" />
                        <b-form-select v-model="additionalFieldValues[fieldDefinition.fieldId]"
                            :options="fieldDefinition.values" v-if="fieldDefinition.type === 'Dropdown'">

                        </b-form-select>
                        <ListProfileField :field="fieldDefinition" v-model="additionalFieldValues[fieldDefinition.fieldId]"
                            v-if="fieldDefinition.type === 'List'" />
                        <p style="font-size: 12px;" class="mt-2" v-if="fieldDefinition.help"
                            v-html="formatHelpText(fieldDefinition.help)"> </p>
                    </b-form-group>
                </b-tab>

                <b-tab title="Annual Reviews" v-if="getFeatures.includes('OrganisationUserReview')">
                    <AnnualReviews />
                </b-tab>

            </b-tabs>

            <div class="actions mt-3">
                <b-col sm="12" lg="12" class="actions-right">
                        <b-button variant="dark" class="mb-0 mt-2 ovr-button__inverted actions-save__close-profile button-continue__profile actions-save__close actions-save__continue-profile" v-b-tooltip.hover title="Save current work and continue editing"  @click="saveProfile(false)">Save and Continue</b-button>
                        <b-button @click="saveAndClose" class="mt-2 ovr-button__inverted actions-save__close button-close__profile actions-save__close-profile" v-b-tooltip.hover title="Save current work and exit" variant="dark">Save and Close</b-button>
                </b-col>

            </div>


		</b-card>

		<b-card no-body class="mb-4" v-if="!loading && personalProfile" @change="profileChanged()">

		</b-card>

		<PageTour ref="pageTourRef" description="Here is where you keep all your information like professional qualifications and information that generally you might find in your CV like a summary of recent work, your current job role, and important sections that relate to you as a professional or student. <br> <br> Ok, let's tour:" @handleShowTour="handleShowTour"  />
		<v-tour name="profileTour" :steps="steps" :options="{ highlight: true }"></v-tour>
		<Pulse loading v-if="loading" class="loader" color="#999999" />

		<AutosaveModal ref="autosaveModal" @leave-without-saving="leaveRoute" @save-and-exit="saveAndClose" />


    </div>
</template>

<script>
import Pulse from "../../components/loaders/Pulse";
import ProfileBasicDetails from "../../components/profile/ProfileBasicDetails";
import ProfileGoverningBody from "../../components/profile/ProfileGoverningBody";
import AutosaveModal from "../../components/entries/AutosaveModal";
import ContentSaveIcon from "vue-material-design-icons/ContentSave";
import ContentSaveEditIcon from "vue-material-design-icons/ContentSaveEdit";
import EventBus from "../../event-bus";
import ListProfileField from "../../components/profile/ListProfileField";
import { mapGetters } from 'vuex'
import RichTextEditor from "../../components/common/RichTextEditor";
import AnnualReviews from "../../components/profile/AnnualReviews";
import CustomInput from '../../components/common/CustomInput';
import ProfileEntryTags from "@/components/profile/ProfileEntryTags";
import PageTour from "../../components/common/PageTour";
import ShowTourButton from "../../components/common/ShowTourButton";

export default {
    name: "Profile",
    components: {
        ProfileEntryTags,
        AnnualReviews,
        RichTextEditor,
        CustomInput,
		PageTour,
		ShowTourButton,
        ListProfileField, 
        AutosaveModal, 
        ProfileGoverningBody, 
        ProfileBasicDetails, 
        Pulse, 
        ContentSaveIcon, 
        ContentSaveEditIcon
    },
    computed: {
        ...mapGetters('features', [
            'getFeatures'
        ])
    },
    data: function () {
        return {
            baseUrl: '',
            personalProfile: null,
            profileImage: null,
            loading: false,
            edited: false,
            continueRoute: null,
            governingBodies: [],
            interestOptions: [
                'Advanced Practice',
                'Course Provider',
                'Education',
                'Educational Support',
                'Emergency Medicine',
                'Lecturing',
                'Mental Heath',
                'Mentorship',
                'Practice Education',
                'Preceptorship',
                'Safeguarding',
                'Specialist Field',
                'Voluntary Service'
            ],
            reportIntervalOptions: [
                { value: 1, text: 'Monthly' },
                { value: 3, text: 'Quarterly' },
                { value: 12, text: 'Annually' },
                { value: 24, text: 'Biennium - 2 years' },
                { value: 36, text: 'Triennial - 3 years'},
                { value: 48, text: 'Quadrennial - 4 years'},
                { value: 60, text: 'Quinquennial - 5 years'}
            ],
            additionalFieldGroups: [],
            additionalFieldValues: [],
            branding: null,
						steps: [
				{
					target: '.basic-details__tour',
					content: `Here you can find your personal and professional information. You can also add an avatar or image of yourself to your profile. <br> In this section you can also see your membership status, manage your subscription and see when your membership is due. <br> <br> If you need help, remember that you can contact our support team at any time.`
				},
                {
                    target: '.professional-information__tour',
                    content: `In these sections you can put information that you might need to pull into your professional portfolio such as your profession and qualifications. Additional information could include a summary of recent work, your job description, and a personal statement.`
                },
				{
					target: '.governing-body__tour',
					content: `When you registered, you may have selected the governing body you are regulated with.
                    <br><br> From here you can add or amend your governing body by clicking the edit option.`
				},
				{
					target: '.profile-tag__entry-tour',
					content: `A great feature to personalise your CPD entries. <br><br> Click to create your CPD tags that you can link to your CPD Diary and Reflective Entries.`
				},
				{
					target: '.button-continue__profile',
					content: `You can choose ‘Save and Continue’ if you are continuing to make changes and wish to save your progress so far.`,
                    params: {
                        placement: 'top'
                    }
				},
				{
					target: '.button-close__profile',
					content: `Here you can choose ‘Save and Close’ to save any changes and close the page.`,
                    params: {
                        placement: 'top'
                    }
				},
			]
        }
    },
    methods: {
        handleShowTour() {
			this.$tours['profileTour'].start()
		},
        loadPersonalProfile() {
            this.loading = true;
            this.$http.get('personal-profile').then((resp) => {
                this.personalProfile = resp.body;
            }).catch((resp) => {
                this.$httpError('Failed to load profile', resp);
            }).finally(() => {
                this.loading = false;
            });
        },
        governingBodiesUpdated() {
            this.loadGoverningBodies();
            this.loadAdditionalFields();
            this.loadAdditionalFieldValues();
        },
        loadGoverningBodies() {
            this.loading = true;
            this.$http.get('profile/governing-bodies').then((resp) => {
                this.governingBodies = resp.body;
                this.loading = false;
            }).catch((err) => {
                this.$httpError('Failed to get governing bodies', err);
            }).finally(() => {
                this.loading = false;
            });
        },
        loadAdditionalFields() {
            this.$http.get('personal-profile/additional-fields').then((resp) => {
                this.additionalFieldGroups = resp.body;
            }).catch((resp) => {
                this.$httpError('Failed to load additional fields', resp);
            });
        },
        loadAdditionalFieldValues() {
            this.$http.get('personal-profile/additional-fields/values').then((resp) => {
                let newValue = [];
                let values = resp.body;
                for (let value of values) {
                    newValue[value.fieldId] = value.value;
                }
                this.additionalFieldValues = newValue;
            }).catch((resp) => {
                this.$httpError('Failed to load additional field values', resp);
            })
        },
        profileChanged() {
            this.edited = true;
        },
        saveAdditionalFieldValues() {
            if (this.additionalFieldGroups.length > 0) {
                let data = [];
                for (let fieldId in this.additionalFieldValues) {
                    data.push({
                        fieldId: parseInt(fieldId),
                        value: this.additionalFieldValues[fieldId]
                    });
                }
                this.$http.put('personal-profile/additional-fields/values', data).then(() => {
                    this.loadAdditionalFieldValues();
                }).catch((resp) => {
                    this.$httpError('Failed to save additional fields', resp);
                })
            }
        },
        saveProfile(close = false) {
            this.loading = true;
            this.$http.put('personal-profile', this.personalProfile).then((resp) => {
                this.personalProfile = resp.body;
                this.loading = false;
                this.edited = false;
                this.saveAdditionalFieldValues();
                EventBus.$emit('show-toast', { title: 'Saved Profile', message: 'Your profile has been updated successfully.' });
                if (close) {
                    this.$router.push({ 'name': 'dashboard-home' });
                }
            }).catch((resp) => {
                this.$httpError('Failed to update profile', resp);
            }).finally(() => {
                this.loading = false;
            })
        },
        leaveRoute() {
            if (this.continueRoute) {
                this.continueRoute();
            }
        },
        saveAndClose() {
            this.saveProfile(true);
        },
        loadBranding() {
            this.$http.get('branding').then((resp) => {
                this.branding = resp.body;
            }).catch(() => { });
        },
        formatHelpText(text) {
            return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
    },
    created() {
        this.loadPersonalProfile();
        this.loadGoverningBodies();
        this.loadAdditionalFields();
        this.loadAdditionalFieldValues();
        this.loadBranding();
    },
    beforeRouteLeave(to, from, next) {
        if (this.edited) {
            this.$refs.autosaveModal.show();
            this.continueRoute = next;
        } else {
            next();
        }
    }
}
</script>

<style lang="scss">
.button-area {
	text-align: center;
}

.profile-tab__navbar {
    background-color: $blue-700 !important;
    border: none !important;

    .nav-link {
        border: none !important;
        color: $blue-900 !important;
        font-weight: 600;
        font-size: 14px;
    }
}

.profile-tab__active {
	background-color: white !important;
	border: none !important;
	border-radius: 8px 8px 0 0 !important;
}

.governing-bodies__card {
	.custom-switch {
		flex: 50%;
		margin-right: 0;
	}

	.interests-checkboxes {
		display: flex;
		flex-wrap: wrap;
	}
}

@media (max-width: 768px) {

    .governing-bodies__card {
        .custom-switch {
            flex: 100%;
        }
    }
}
</style>

<style lang="scss" scoped>
.governing-bodies__card {
	background-color: $background;
}


.actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.actions-left,
.actions-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.actions-save__close {
    justify-content: flex-end;
    display: flex;
    color: white;
    font-weight: 600;
    margin-left: 32px;
    background: $blue-300 !important;
    border-radius: 8px;
    padding: 0.375rem 22px;
}

@media screen and (max-width: 991px) {
	.actions-right, .actions-save__close-profile, .actions-save__continue-profile {
		justify-content: center;
	}
}

@media screen and (max-width: 768px) {
    .actions-left,
    .actions-save__close {
        justify-content: center;
        display: flex;
    }
}

</style>
